var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("RadixModule", {
    attrs: { path: "/calls/by-date/calls-by-date", state: _vm.state }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }