<template>

<SearchTable name="calls" title="Звонки" :columns=columns dynamic quickSearch=dst apiTopic=phone apiPath="/calls?paged=1" 
 metaApiTopic="phone" metaApiPath="/meta/calls/search"
>
  <template #calldate="{rec}">
    {{rec.calldate}} -- {{rec.disposition}}
  </template>

  <template #dst="{rec}">
    <span :title="dstTitle(rec)">
    <template v-if="isIngoing(rec)">
      <span title="Входящий" class="glyphicon glyphicon-import" aria-hidden="true"></span>
      {{ rec.src }}
    </template>    
    <template v-else>
      <span title="Исходящий" class="glyphicon glyphicon-export" aria-hidden="true"></span>
      {{ rec.dst }}
    </template>
    </span>
  </template>

  <template #billsec="{rec}">
    {{ common.formatUptime(rec.billsec) }}
  </template>

  <template #record_not_exist="{rec}">
    <span class="text-center" title="Активен" v-if="rec.has_record">
      <PhoneCall :record=rec.uniqueid />
    </span>
    <span title="Запись удалена" class="text-center text-muted" v-else>
      <span class="glyphicon glyphicon-minus "  aria-hidden="true"></span>
    </span>
  </template>

  <template #worker="{rec}">
    <a v-if="rec.worker && rec.worker != 'unknown'" href="'/worker/'+rec.worker">{{rec.worker}}</a>
  </template>

</SearchTable>
</template>
  
<script>

  import PhoneCall from "common/visual/PhoneCall.vue"

  export default {
  
    props: {
    },
    
    components: {PhoneCall},
  
    name: "CallsTable",
    
    data()
    {
      return {
        
        columns: [
          {
            name: "calldate",
            title: "Дата",
            sort: true,
          },
          {
            name: "dst",
            title: "Номер",
          },
          {
            name: "billsec",
            title: "Длительность",
          },
          {
            name: "record_not_exist",
            title: "Запись",
            width: 50,
          },
          {
            name: "worker",
            title: "Работник",
            sort: true,
          },
        ],
      }
    },
    
    methods: {
      isIngoing(rec)
      {
        return rec.dst.length <= 3 || rec.dst == 'hangup'
      },
      dstTitle(rec)
      {
        return `dst = ${rec.dst}; src = ${rec.src}`
      },
    },
    
  }
</script>
