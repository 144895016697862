define([
  'common/visual/overlay',
  'backbone-ex',
  'darsan',
  'common',
  
  'common/visual/list',
  'text-loader!calls/page/toolbar.tpl',
  'text-loader!calls/page/row.tpl',
  'text-loader!calls/page/table.tpl',

  'common/visual/extlist',
  'calls/by-date/calls-by-date',

], function(Overlay, Backbone, darsan, common, List, toolbarTemplate, rowTemplate, tableTemplate,
            Extlist, callsByDate)
{
  var listMod = Object.create(List).extend({
    name: "callsList",
    title: "Звонки",
    rowTemplate: rowTemplate,
    tableTemplate: tableTemplate,
    toolbarTemplate: toolbarTemplate
  });

  var flistMod = Object.create(Extlist).extend({
    name: "callsExtList",
    listName: "calls",
    listModule: listMod,
    searchMetaAddress: ["","phone","/meta/calls/search"]
  });

  return Object.create(Overlay).extend({
    modParams: {},
    name: 'calls',
    title: "Звонки",
    layout: [
      {module: callsByDate},
      {module: flistMod},
      // {module: newMod}
    ],

    create: function(el, options) {
      var me = this;

      var NasModel = Backbone.ModelEx.extend({});
      
      var url = darsan.makeUrl("", "phone", "/calls");
      var NasList = Backbone.CollectionExPageable.extend({
        model: NasModel,
        url: url,
      });

      this.collection = new NasList();
      this.modParams.collection = this.collection;
      Overlay.create.call(me, el, options);

       this.$el.on('click', '.jpm-play', function(ev) {
        var me = this;
        var tr = $(ev.target).closest('.jpm-play');

        var id = tr.attr('data-entity');
        darsan.promiseDarsanToken2().done(function(rec) 
        {
          var p = '?darsan2=' + encodeURIComponent(rec.token);
          if (rec.pretend) p += "&darsan-pretend=" + rec.pretend;
          var url = darsan.makeUrl('','phone', '/record/' + id + p);
//	  tr.parent().html('<audio controls style="width:100%;" preload="none" autoplay="autoplay" src="'+url+'"></audio><span class="fa fa-fast-forward"></span>');

	  var $el = $('<div style="display:flex;align-items:center;"><span title="Ускореное воспроизведение">2x <input type="checkbox"></span>&nbsp;<audio style="width:100%;" controls preload="none" autoplay="autoplay" src="'+url+'"></audio></div>');
	  $el.on('change', 'input', function(e){
		$el.find('audio').get(0).playbackRate = ($(e.currentTarget).is(":checked")) ? 2 : 1;
	  });

	  tr.parent().html($el);

        });
      });

    },

    neededOverlay: function (state, name) {
      if (name != "calls") throw "No overlay for " + name;

      if ( state._rest && state._rest != "/" ) {
        return "callsByDate";
      } else {
        return "callsExtList";
      }
    }
  });
});
