<template>
  <keep-alive>
    <component :is=currentComponent :state=state />
  </keep-alive>
</template>

<script>

import CallsByDate from "calls/page/bydate.vue"
import CallsTable from "calls/page/table.vue"

import { eventBus } from "main"

export default {

  name: "CallsPage",
  
  components: {CallsByDate, CallsTable},
  
  props: {
  },
  
  data()
  {
    return  {
      state: {},
      currentComponent: null,
    }
  },
  
  created()
  {
    eventBus.on("routeChanged", ({path,params, fragment}) => this.setState(path,params, fragment))
  },
  
  methods:{
    setState(path, params, fragment)
    {
      if (path=="/calls")
      {
        this.currentComponent = CallsTable
        this.state = {}
      }
      else
      {
        this.currentComponent = CallsByDate
        this.state = {
          _rest: path.replace(/^\/calls/,""),
          fragment
        }
      }
    },
  },

}
</script>
