define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common/visual/visual',
  'navigation',
  'common',
  'moment',

  'text-loader!calls/by-date/layout.tpl',

  'calls/by-date/calls-by-date.css'
], function (
  $,
  _,
  Backbone,
  darsan,
  visual,
  navigation,
  common,
  moment,
  callsByDateTemplate
) {
  moment.locale('ru');

  return Object.create(visual).extend({
    parent: null,
    name  : 'callsByDate',

    create: function (el, opt) {
      var me = this;

      visual.create.apply(me, arguments);

      var WikiView = Backbone.TemplateCooperView.extend({
        events: {
          'click .jpm-play' : me._play,
        },
        template: callsByDateTemplate,
        navigation: navigation,
        common    : common,
        render: function ()
        {
          me.collection2.forEach(el => el.set("outgoing", true));
          me.collection.add(me.collection2.toJSON());
	
	  me.collection.map(function(model){
		model.set("unixtime", moment(model.get("time")).unix());
	  });

          me.collection.comparator = "unixtime";
          me.collection.sort();

	  //Делим звонки на отвеченные и нет
	  var a = [], n_a = [];
	  me.collection.forEach(function(model){
		if (model.get("answered")==0 && !model.get("outgoing")){
		     n_a.push(model);
		}else{
		    a.push(model);
		}
	  });

	const r = new RegExp(/(?:\+?7)?([0-9]{10})/)

	  //Ищем звонки по которым
	_.forEach(n_a, function(model)
	{
	     model.set('recall', 0);

	    var no = String(model.get('caller')).match(r);

	    _.some(a, function(model2){

		//На неотвеченный звонок перзвонили или абонент звонил поже
		var no2 = String(model2.get('caller')).match(r);

		  if( no && no2 && no[1] == no2[1] && model2.get('unixtime') > model.get('unixtime') ){
		      model.set('recall', 1)
		      return true
	  	}
	  });
	});

        var operators = _.groupBy(a, el => el.get("operator"));

	//Фильтруем неотвеченные по городам
	if( config.domain == 'mak' ){
	    var city = [{ id: 700, name: 'Макеевка' },{ id: 800, name: 'Ясиноватая' },{ id: 900, name: 'Пантелеймоновка' }];

	    var h = {'Не отвеченные': []};
	    _.map(city, function(v){ h[v.id] = []; });

	    _.map(n_a, function(model){
		if(model.get('queue') in h){
		    h[model.get('queue')].push(model);
		}else{
		    h['Не отвеченные'].push(model);
		}
	    });

	    _.map(city, function(v){ 
		if(!_.isEmpty(h[v.id])) operators["Не отвеченные ("+v.name+")"] = h[v.id];
	    });
	    if(!_.isEmpty(h['Не отвеченные'])) operators["Не отвеченные"] = h['Не отвеченные'];

	}else{
	    operators['Не отвеченные'] = n_a;
	}

	this.$el.html(this.compiled({ operators, common, moment }));
          return this;
        }
      });

      me.view = new WikiView({
        el: me.$el
      });
      
      var CallModel = Backbone.EntityModel.extend({});
      var url = darsan.makeUrl('', 'card', '/billing/card');
            
      var IngoingList = Backbone.EntityCollection.extend({
        model: CallModel,
        url: darsan.makeUrl('', 'phone', '/ingoing-calls'),
      });

      var OutgoingList = Backbone.EntityCollection.extend({
        model: CallModel,
        url: darsan.makeUrl('', 'phone', '/outgoing-calls'),
      });
                                                
      this.collection = new IngoingList();
      this.collection2 = new OutgoingList();

      me.view.parent = me;
    },

    setState: function(state) 
    {
      var me = this;

      me.state = me.state || {};
      var date = state._rest.substr(1);

      if (me.state._rest == date) return;
      me.state = state;

      $.when(
        me.collection.fetch({ reset: true, data: {date: date} }),
        me.collection2.fetch({ reset: true, data: {date: date} })
      ).then(() =>
      {
        me.view.render();
        const el = document.getElementById(window.location.hash.replace(/#/,""))
        if (el) el.scrollIntoView()
      });
    },
    
    _play: function(ev) 
    {
        var me = this;
        var tr = $(ev.target).closest('.jpm-play');

        var id = tr.attr('data-entity');
        darsan.promiseDarsanToken2().done(function(rec) 
        {
          var p = '?darsan2=' + encodeURIComponent(rec.token);
          if (rec.pretend) p += "&darsan-pretend=" + rec.pretend;
          var url = darsan.makeUrl('','phone', '/record/' + id + p);

	  var $el = $('<div style="display:flex;align-items:center;"><span title="Ускореное воспроизведение">2x <input type="checkbox"></span>&nbsp;<audio style="width:100%;" controls preload="none" autoplay="autoplay" src="'+url+'"></audio></div>');
	  $el.on('change', 'input', function(e){
		$el.find('audio').get(0).playbackRate = ($(e.currentTarget).is(":checked")) ? 2 : 1;
	  });

	  tr.parent().html($el);
        });
      },
    
  });
});
